import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { AuthState } from '../../state/auth/auth.state';
import { Router } from '@angular/router';
import { lastValueFrom, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthResponseInterface } from '../../state/auth/interfaces/auth-response.interface';
import { WindowLocationService } from '../../services/window-location.service';
import { PreloadAction } from '../../shared/modules/air/air-modules/actions/interfaces/preload.action';
import { pages } from 'libraries/college-pages';

@Injectable()
export class AutoLoginAction implements PreloadAction {
  constructor(
    private windowLocationService: WindowLocationService,
    @Inject(WINDOW) readonly window: Window,
    private authState: AuthState,
    private router: Router,
  ) {}

  preload(): Promise<AuthResponseInterface | undefined> {
    const queryParams = this.windowLocationService.queryParams;
    const refreshToken = queryParams.refresh_token;

    if (refreshToken) {
      delete queryParams.refresh_token;
      return lastValueFrom(
        this.authState.autoLogin(refreshToken).pipe(
          tap((auth) => {
            if (!auth?.auth.suspended) {
              this.router.navigate([this.window.location.pathname], { queryParams }).then();
            } else {
              this.router.navigate([pages.suspended]).then();
            }
          }),
          catchError(() => of()),
        ),
      );
    } else {
      return lastValueFrom(of(undefined));
    }
  }
}
